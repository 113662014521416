var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "h-full min-contents" },
    [
      _c(
        "div",
        {
          ref: "adminList",
          staticClass: "container mx-auto mb-6",
          attrs: { id: "admin-list" },
        },
        [
          _c("div", { staticClass: "grid grid-cols-3 gap-x-6 mt-4" }, [
            _vm.loginUserRole === "manager" && !_vm.isEdit && !_vm.editManagerId
              ? _c("div", { staticClass: "col-start-3 col-span-1 my-4 h-11" }, [
                  _c("div", { staticClass: "w-1/2 h-full float-right" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "w-full h-full px-3 py-2 bg-base-yellow font-bold",
                        on: {
                          click: function ($event) {
                            _vm.isEdit = !_vm.isEdit
                          },
                        },
                      },
                      [_vm._v(" 管理者追加 ")]
                    ),
                    _vm.isEdit
                      ? _c(
                          "button",
                          {
                            staticClass:
                              "w-full h-full px-3 py-2 bg-very-light-grey font-bold",
                            on: {
                              click: function ($event) {
                                _vm.isEdit = !_vm.isEdit
                              },
                            },
                          },
                          [_vm._v(" キャンセル ")]
                        )
                      : _vm._e(),
                  ]),
                ])
              : _vm._e(),
          ]),
          _c(
            "div",
            { ref: "divRef", staticClass: "contents_list my-10" },
            [
              _c(
                "ValidationObserver",
                { ref: "updateManager" },
                _vm._l(_vm.managers, function (manager, key) {
                  return _c(
                    "div",
                    {
                      key: key,
                      staticClass:
                        "grid grid-cols-12 gap-x-6 border border-gray-500 h-11 mb-4",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-span-2 border-r border-gray-500 font-bold text_item_center my-1",
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm._f("padZero")(manager.id)) + " "
                          ),
                        ]
                      ),
                      _vm.editManagerId == manager.uuid
                        ? [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-span-8 grid grid-cols-12 text-xs",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "col-span-4 text_item_start" },
                                  [
                                    _c("ValidationProvider", {
                                      class: "mr-2",
                                      attrs: {
                                        tag: "span",
                                        vid: "last_name",
                                        rules: { required: true, max: 255 },
                                        name: "Last name",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.inputValue
                                                          .last_name,
                                                      expression:
                                                        "inputValue.last_name",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "input_text w-full h-8 mr-2",
                                                  attrs: {
                                                    type: "text",
                                                    placeholder: "Last name",
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.inputValue.last_name,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.inputValue,
                                                        "last_name",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "p",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: errors.length,
                                                        expression:
                                                          "errors.length",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "text-xxs text-left text-red-600 absolute",
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                    _c("ValidationProvider", {
                                      class: "mr-2",
                                      attrs: {
                                        tag: "span",
                                        vid: "first_name",
                                        rules: { required: true, max: 255 },
                                        name: "First name",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.inputValue
                                                          .first_name,
                                                      expression:
                                                        "inputValue.first_name",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "input_text w-full h-8 mr-2",
                                                  attrs: {
                                                    type: "text",
                                                    placeholder: "First name",
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.inputValue.first_name,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.inputValue,
                                                        "first_name",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "p",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: errors.length,
                                                        expression:
                                                          "errors.length",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "text-xxs text-left text-red-600 absolute",
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-span-4 text_item_start" },
                                  [
                                    _c("ValidationProvider", {
                                      class: "inline-block mr-2",
                                      attrs: {
                                        tag: "span",
                                        vid: "email",
                                        rules: { required: true, max: 255 },
                                        name: "Email",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.inputValue.email,
                                                      expression:
                                                        "inputValue.email",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "input_text w-full h-8",
                                                  attrs: {
                                                    type: "email",
                                                    placeholder: "Email",
                                                  },
                                                  domProps: {
                                                    value: _vm.inputValue.email,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        _vm.inputValue,
                                                        "email",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c(
                                                  "p",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: errors.length,
                                                        expression:
                                                          "errors.length",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "text-xxs text-left text-red-600 absolute",
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-span-2 text_item_start" },
                                  [
                                    _c("ValidationProvider", {
                                      class: "w-full mx-2",
                                      attrs: {
                                        tag: "span",
                                        vid: "role",
                                        rules: { required: true },
                                        name: "Role",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c(
                                                  "select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.inputValue.role,
                                                        expression:
                                                          "inputValue.role",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "input_text w-full h-8",
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$selectedVal =
                                                          Array.prototype.filter
                                                            .call(
                                                              $event.target
                                                                .options,
                                                              function (o) {
                                                                return o.selected
                                                              }
                                                            )
                                                            .map(function (o) {
                                                              var val =
                                                                "_value" in o
                                                                  ? o._value
                                                                  : o.value
                                                              return val
                                                            })
                                                        _vm.$set(
                                                          _vm.inputValue,
                                                          "role",
                                                          $event.target.multiple
                                                            ? $$selectedVal
                                                            : $$selectedVal[0]
                                                        )
                                                      },
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.role,
                                                    function (role, key) {
                                                      return _c(
                                                        "option",
                                                        {
                                                          key: key,
                                                          domProps: {
                                                            value: role.value,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  role.text
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                                _c(
                                                  "p",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: errors.length,
                                                        expression:
                                                          "errors.length",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "text-xxs text-left text-red-600 absolute",
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-span-2 text_item_start" },
                                  [
                                    _c("ValidationProvider", {
                                      class: "w-full mx-2",
                                      attrs: {
                                        tag: "span",
                                        vid: "preferred_language",
                                        rules: { required: true },
                                        name: "Preferred Language",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c(
                                                  "select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.inputValue
                                                            .preferred_language,
                                                        expression:
                                                          "inputValue.preferred_language",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "input_text w-full h-8 mx-2",
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        var $$selectedVal =
                                                          Array.prototype.filter
                                                            .call(
                                                              $event.target
                                                                .options,
                                                              function (o) {
                                                                return o.selected
                                                              }
                                                            )
                                                            .map(function (o) {
                                                              var val =
                                                                "_value" in o
                                                                  ? o._value
                                                                  : o.value
                                                              return val
                                                            })
                                                        _vm.$set(
                                                          _vm.inputValue,
                                                          "preferred_language",
                                                          $event.target.multiple
                                                            ? $$selectedVal
                                                            : $$selectedVal[0]
                                                        )
                                                      },
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.language,
                                                    function (language, key) {
                                                      return _c(
                                                        "option",
                                                        {
                                                          key: key,
                                                          domProps: {
                                                            value:
                                                              language.value,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  language.text
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                                _c(
                                                  "p",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: errors.length,
                                                        expression:
                                                          "errors.length",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "text-xxs text-left text-red-600 absolute",
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-span-2 text_item_start flex text-xs",
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "px-4 py-1 mr-1 bg-base-yellow font-bold",
                                    on: {
                                      click: function ($event) {
                                        return _vm.updateManager(manager)
                                      },
                                    },
                                  },
                                  [_vm._v("更新")]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "px-2 py-1 bg-very-light-grey font-bold",
                                    on: { click: _vm.updateCancel },
                                  },
                                  [_vm._v("キャンセル")]
                                ),
                              ]
                            ),
                          ]
                        : [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-span-8 grid grid-cols-12 gap-x-3 text-xs",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "col-span-3 text_item_start" },
                                  [
                                    _c("img", {
                                      staticClass: "w-4 h-4 inline-block",
                                      attrs: {
                                        src: require("@/assets/image/icon_list-02.svg"),
                                      },
                                    }),
                                    _c("FlTruncatableSpan", {
                                      attrs: { value: manager.name },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-span-5 text_item_start" },
                                  [
                                    _c("img", {
                                      staticClass: "w-4 h-4 inline-block",
                                      attrs: {
                                        src: require("@/assets/image/icon_list-03.svg"),
                                      },
                                    }),
                                    _c("FlTruncatableSpan", {
                                      attrs: { value: manager.email },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-span-2 text_item_start" },
                                  [
                                    _c("FlTruncatableSpan", {
                                      attrs: {
                                        value: _vm.textOfEnum(
                                          _vm.role,
                                          manager.role
                                        ),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-span-2 text_item_start" },
                                  [
                                    _c("FlTruncatableSpan", {
                                      attrs: {
                                        value: _vm.textOfEnum(
                                          _vm.language,
                                          manager.preferred_language
                                        ),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm.loginUserRole === "manager"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-span-1 text_item_start flex text-xs",
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "w-24 px-1 py-1 mr-1 bg-base-yellow font-bold",
                                        on: {
                                          click: function ($event) {
                                            return _vm.editManager(manager)
                                          },
                                        },
                                      },
                                      [_vm._v("編集")]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.loginUserRole === "manager"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-span-1 text_item_start flex text-xs",
                                  },
                                  [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "w-24 py-1 mx-1 bg-brown-grey font-bold",
                                        on: {
                                          click: function ($event) {
                                            return _vm.checkDelete(manager)
                                          },
                                        },
                                      },
                                      [_vm._v("削除")]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                    ],
                    2
                  )
                }),
                0
              ),
              _vm.isEdit && !_vm.editManagerId
                ? _c(
                    "div",
                    {
                      staticClass:
                        "grid grid-cols-12 gap-x-6 border border-gray-500 h-11 mb-4",
                    },
                    [
                      _c("div", {
                        staticClass:
                          "col-span-2 border-r border-gray-500 font-bold text_item_center",
                      }),
                      _c(
                        "ValidationObserver",
                        {
                          ref: "addManager",
                          staticClass: "col-span-8 grid grid-cols-12 text-xs",
                          attrs: { tag: "div" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "col-span-4 text_item_start" },
                            [
                              _c("ValidationProvider", {
                                class: "mr-2",
                                attrs: {
                                  tag: "span",
                                  vid: "last_name",
                                  rules: { required: true, max: 255 },
                                  name: "Last name",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.inputValue.last_name,
                                                expression:
                                                  "inputValue.last_name",
                                              },
                                            ],
                                            staticClass:
                                              "input_text w-full h-8 mr-2",
                                            attrs: {
                                              type: "text",
                                              placeholder: "Last name",
                                            },
                                            domProps: {
                                              value: _vm.inputValue.last_name,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.inputValue,
                                                  "last_name",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _c(
                                            "p",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: errors.length,
                                                  expression: "errors.length",
                                                },
                                              ],
                                              staticClass:
                                                "text-xxs text-left text-red-600 absolute",
                                            },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1567015519
                                ),
                              }),
                              _c("ValidationProvider", {
                                class: "mr-2",
                                attrs: {
                                  tag: "span",
                                  vid: "first_name",
                                  rules: { required: true, max: 255 },
                                  name: "First name",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.inputValue.first_name,
                                                expression:
                                                  "inputValue.first_name",
                                              },
                                            ],
                                            staticClass:
                                              "input_text w-full h-8 mr-2",
                                            attrs: {
                                              type: "text",
                                              placeholder: "First name",
                                            },
                                            domProps: {
                                              value: _vm.inputValue.first_name,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.inputValue,
                                                  "first_name",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _c(
                                            "p",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: errors.length,
                                                  expression: "errors.length",
                                                },
                                              ],
                                              staticClass:
                                                "text-xxs text-left text-red-600 absolute",
                                            },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3406451951
                                ),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-span-2 text_item_start" },
                            [
                              _c("ValidationProvider", {
                                class: "inline-block mr-2",
                                attrs: {
                                  tag: "span",
                                  vid: "email",
                                  rules: { required: true, max: 255 },
                                  name: "Email",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.inputValue.email,
                                                expression: "inputValue.email",
                                              },
                                            ],
                                            staticClass:
                                              "input_text w-full h-8",
                                            attrs: {
                                              type: "email",
                                              placeholder: "Email",
                                            },
                                            domProps: {
                                              value: _vm.inputValue.email,
                                            },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.inputValue,
                                                  "email",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                          _c(
                                            "p",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: errors.length,
                                                  expression: "errors.length",
                                                },
                                              ],
                                              staticClass:
                                                "text-xxs text-left text-red-600 absolute",
                                            },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3266834799
                                ),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-span-2 text_item_start" },
                            [
                              _c("ValidationProvider", {
                                class: "inline-block mr-2",
                                attrs: {
                                  tag: "span",
                                  vid: "password",
                                  rules: { required: true, min: 6 },
                                  name: "Password",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.password,
                                                expression: "password",
                                              },
                                            ],
                                            staticClass:
                                              "input_text w-full h-8",
                                            attrs: {
                                              type: "password",
                                              placeholder: "Password",
                                            },
                                            domProps: { value: _vm.password },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.password =
                                                  $event.target.value
                                              },
                                            },
                                          }),
                                          _c(
                                            "p",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: errors.length,
                                                  expression: "errors.length",
                                                },
                                              ],
                                              staticClass:
                                                "text-xxs text-left text-red-600 absolute",
                                            },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3407855835
                                ),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-span-2 text_item_start ml-3" },
                            [
                              _c("ValidationProvider", {
                                class: "mx-2",
                                attrs: {
                                  tag: "span",
                                  vid: "role",
                                  rules: { required: true },
                                  name: "Role",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.inputValue.role,
                                                  expression: "inputValue.role",
                                                },
                                              ],
                                              staticClass:
                                                "input_text w-full h-8",
                                              on: {
                                                change: function ($event) {
                                                  var $$selectedVal =
                                                    Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function (o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function (o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                  _vm.$set(
                                                    _vm.inputValue,
                                                    "role",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                },
                                              },
                                            },
                                            _vm._l(
                                              _vm.role,
                                              function (role, key) {
                                                return _c(
                                                  "option",
                                                  {
                                                    key: key,
                                                    domProps: {
                                                      value: role.value,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(role.text)
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                          _c(
                                            "p",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: errors.length,
                                                  expression: "errors.length",
                                                },
                                              ],
                                              staticClass:
                                                "text-xxs text-left text-red-600 absolute",
                                            },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  4281757102
                                ),
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-span-2 text_item_start ml-3" },
                            [
                              _c("ValidationProvider", {
                                class: "mx-2",
                                attrs: {
                                  tag: "span",
                                  vid: "preferred_language",
                                  rules: { required: true },
                                  name: "Preferred Language",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (ref) {
                                        var errors = ref.errors
                                        return [
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.inputValue
                                                      .preferred_language,
                                                  expression:
                                                    "inputValue.preferred_language",
                                                },
                                              ],
                                              staticClass:
                                                "input_text w-full h-8 mx-2",
                                              on: {
                                                change: function ($event) {
                                                  var $$selectedVal =
                                                    Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function (o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function (o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                  _vm.$set(
                                                    _vm.inputValue,
                                                    "preferred_language",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                },
                                              },
                                            },
                                            _vm._l(
                                              _vm.language,
                                              function (language, key) {
                                                return _c(
                                                  "option",
                                                  {
                                                    key: key,
                                                    domProps: {
                                                      value: language.value,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(language.text)
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                          _c(
                                            "p",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: errors.length,
                                                  expression: "errors.length",
                                                },
                                              ],
                                              staticClass:
                                                "text-xxs text-left text-red-600 absolute",
                                            },
                                            [_vm._v(_vm._s(errors[0]))]
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2870034136
                                ),
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-span-2 text_item_start flex text-xs",
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass:
                                "px-4 py-1 mr-1 bg-base-yellow font-bold",
                              on: { click: _vm.createManager },
                            },
                            [_vm._v("追加")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass:
                                "px-2 py-1 bg-very-light-grey font-bold",
                              on: { click: _vm.chancel },
                            },
                            [_vm._v("キャンセル")]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { ref: "RefScroll" }),
            ],
            1
          ),
        ]
      ),
      _vm.removeManager
        ? _c("NoticeModal", {
            attrs: {
              modalName: "checkDelete",
              title: "アカウントの削除",
              contents:
                _vm.removeManager.name +
                "のアカウントを削除します。よろしいですか？",
              buttons: ["キャンセル", "OK"],
            },
            on: {
              click: function ($event) {
                return _vm.deleteManager(_vm.removeManager)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }